import React from 'react'
import Navbar from '../Navbars/Navbar'
import CTA from './CTA';
import Footer from './Footer';
function ProductDemo() {
  return (
    <>
      <Navbar />
      {/* <div className='bg-[#FFFFFF] sm:px-16 px-6 flex justify-center items-start relative' >
                <div className='xl:max-w-[1280px] w-full'>
                    <div className='flex flex-col justify-center items-center sm:py-16 py-6'>
                        <h1 className='lg:text-[48px] text-[38px] font-bold text-center'>Product Demos</h1>
                       <div className="flex justify-center mt-8" >
            <iframe className="lg:w-[80vw] w-[80vw] h-full aspect-video " src="https://www.youtube.com/embed/siGCVexlDCY?si=xlmKB3TvICmAT8sy"></iframe>
          </div>

                    </div>

                </div>
            </div> */}





      <div className='bg-[#FFFFFF] flex justify-center items-start relative' >
        <div className=' w-full'>
          <div className='flex flex-col justify-center items-center sm:pt-16 sm:pb-6 py-6 md:mt-2 mt-5'>
            <div className="flex justify-center lg:mt-0 mt-10" >
              <iframe className="lg:w-[99vw] w-[99vw] lg:h-[60vh] h-full aspect-video" src="https://www.youtube.com/embed/siGCVexlDCY?rel=0&si=xlmKB3TvICmAT8sy" allow="autoplay; encrypted-media"></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className='pt-4 flex justify-center'>
        <h1 className=" underline text-center font-bold font-poppins lg:text-[30px] text-[25px] text-[#231D4F] md:leading-[50px] leading-[38px]">
          Your Ultimate Guide to Using the Gupta App
        </h1>

      </div>

      {/* Start Section */}
      <div className='bg-[#F4FBFF] sm:px-16 px-1 flex justify-center items-start' >
        <div className='xl:max-w-[1280px] w-full'>
          <section className='flex md:flex-row flex-col sm:py-8 py-6'>
          <div className='flex-1 flex   flex-col md:mr-[120px] ml-0 md:mt-[110px] mt-5 relative'>
              <div className="md:flex flex-row   w-full">
                <h1 className="flex text-center font-poppins font-[900] md:text-[40px] text-[27px] text-black md:leading-[48px] leading-[38px]">
                  Customized WhatsApp Links  <br className="sm:block hidden" />
                </h1>
              </div>
              <div>
                <p className='mt-3 text-[#6C757D] '>You can easily create personalized WhatsApp or catalog links and track how often they're clicked. You can also download QR codes for each link, which helps you stay connected with your customers.</p>
              </div>
            </div>
            <div className='flex-1 flex-col justify-center items-start  '>
              <div className="flex justify-center mt-8" >
                <iframe className="lg:w-[45vw] w-[100vw] h-full aspect-video" src="https://www.youtube.com/embed/ieJ2YTdEfBs?rel=0&si=dVkXayVvcnH8xTWh" allow="autoplay; encrypted-media"></iframe>
              </div>
            </div>

          </section>
        </div>
      </div>
      {/* End Section */}

      

      {/* Start Section */}
      <div className='bg-[#FFFFFF] sm:px-16 px-1 flex justify-center items-start md:pt-10' >
        <div className='xl:max-w-[1280px] w-full'>
          <section className='flex md:flex-row flex-col-reverse sm:py-16 py-6'>
            <div className='flex-1 flex-col justify-center items-start  '>
              <div className="flex justify-center mt-8" >
                <iframe className="lg:w-[45vw] w-[100vw] h-full aspect-video" src="https://www.youtube.com/embed/HdX7wSB4XcE?rel=0&si=DOW-YDcxoaqB6YgM" allow="autoplay; encrypted-media"></iframe>
              </div>
            </div>

            <div className='flex-1 flex   flex-col md:ml-[120px] ml-0 md:mt-[110px] mt-5 relative'>
              <div className="md:flex flex-row   w-full">
                <h1 className="flex justify-center  font-poppins font-[900] md:text-[40px] text-[27px] text-black md:leading-[48px] leading-[38px]">
                  Multilinks Creation  
                </h1>
              </div>
              <div>
                <p className='mt-3 text-[#6C757D] '>  You can create a mini webpage with several customized WhatsApp links. You can also add your brand logos and social media links to the page, making it easier for your customers to interact with them.</p>
              </div>


            </div>

          </section>
        </div>
      </div>
      {/* End Section */}

      {/* Start Section */}
      <div className='bg-[#F4FBFF] sm:px-16 px-6 flex justify-center items-start' >
        <div className='xl:max-w-[1280px] w-full'>

          <section className='flex md:flex-row flex-col sm:py-16 py-1'>
            <div className='flex-1 flex justify-center items-start flex-col'>
              <div className="md:flex flex-row justify-between items-center w-full">
                <h1 className="flex-1 font-poppins font-[900] md:text-[40px] text-[27px] text-black md:leading-[50px] leading-[38px]">
                  Custom Website

                </h1>
              </div>

              <p className='mt-3 text-[#6C757D] md:pr-10 pr-1 '>
                You can create your customized website URLs for your shops and add your products to your website. This makes it easy for your customers to browse and shop.
              </p>
            </div>
            <div className='flex-1 flex justify-center items-center'>
              <div className="flex justify-center mt-8" >
                <iframe className="lg:w-[45vw] w-[99vw] h-full aspect-video" src="https://www.youtube.com/embed/G8B35pTAtPo?rel=0&si=R-gBfhFLkh-Gzo80" allowFullScreen></iframe>

              </div>
            </div>
          </section>

        </div>
      </div>
      {/* End Section */}


      {/* Start Section */}
      <div className='bg-[#FFFFFF] sm:px-16 px-6 flex justify-center items-start' >
        <div className='xl:max-w-[1280px] w-full'>

          <section className='flex md:flex-row flex-col sm:py-16 py-6'>
            <div className='flex-1 flex justify-center items-start flex-col'>
              <div className="md:flex flex-row justify-between items-center w-full">
                <h1 className="flex-1 font-poppins font-[900] md:text-[40px] text-[27px] text-black md:leading-[50px] leading-[38px]">
                  Customized Redirect Links

                </h1>
              </div>

              <p className='mt-3 text-[#6C757D]'>
                You can make short, custom URLs that redirect to a specific website. You can see how many times each link is clicked and create QR codes for them, making it even easier to use.
              </p>
            </div>
            <div className='flex-1 flex justify-center items-center'>
              <img src="/images/image2.png" alt="hero" />
            </div>
          </section>

        </div>
      </div>
      {/* End Section */}

      {/* Start Section */}
      <div className='bg-[#0071BC] sm:px-16 px-6 flex justify-center items-start ' >
        <div className='xl:max-w-[1200px] w-full'>
          <CTA />
        </div>
      </div>
      {/* End Section */}
      <div>
        <Footer />
      </div>

    </>
  )
}

export default ProductDemo