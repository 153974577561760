
import React, { useState } from "react";
import { AdminApis } from "../../apis/adminApi";
import { Link, NavLink } from "react-router-dom";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { ToastContainer, toast } from 'react-toastify';
import ImageUploading from 'react-images-uploading';
import Modal from 'react-awesome-modal';
import { useNavigate } from 'react-router-dom';
import { IoIosAddCircle } from "react-icons/io";

// components

export default function CardTiredLinks() {

  const [images, setImages] = React.useState('');
  const navigate = useNavigate();
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const [nameExist, setNameExist] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [logo, setlogo] = useState('');
  const [bio, SetBio] = useState('');
  const [addlink, setAddLink] = useState([]);
  const [businessSite, setBusinessSite] = useState('https://www.mygupta.co');
  const [businessPolicy, setBusinessPolicy] = useState('no policy');
  const [data, setLinks] = useState([]);
  const [isAvailable, setIsAvailable] = useState(false);
  const [permissionList, setPermissionList] = React.useState([]);

  const [loader, setLoader] = React.useState(false);

  const [permissionIdList, setPermissionIdList] = React.useState([]);

  const [fileName, setFileName] = useState("No selected file")
  const [errorMes, setErrorMes] = React.useState("")

  React.useEffect(() => {
    AdminApis.getlinks().then(
      (response) => {
        if (response?.data) {
          setLinks(response?.data?.link)
          setPermissionIdList(response?.data?.link)

          //  console?.log(response?.data?.link)
        }
      }
    );

  }, []);

  React.useEffect(() => {
    AdminApis.searchName({ 'name': name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9\-]/g, '') }).then(
      (response) => {
        if (response?.data) {
          setNameExist(response?.data?.data)
          // console?.log(response?.data)
        }
      }
    );

  }, [name, nameExist]);

  const [selectedPics1, setSelectedPics1] = React.useState('No selected file');
  const changeHandler1 = (e) => {
    // console?.log(e.target.files)
    const target = e.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    setFileName(e.target.files[0].name)
    // console?.log(size)
    if (target.files && target.files[0]) {
      if (size > 1) {
        if (targetName == 'image1') {
          setErrorMes("File too big!")
          target.value = ''
          console.log('error')
        }
      }
      if (size <= 1) {
        if (targetName == 'image1') {
          setErrorMes(" ")
          setSelectedPics1(e.target.files[0]);
          console.log(e.target.files[0])
        }
      }
    }
  };

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      setLoader(true);
      const formData = new FormData()
      formData.append('name', name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9\-]/g, ''))
      formData.append('title', title)
      formData.append('bio', bio)
      formData.append('attach_links', permissionList?.toString())
      formData.append('logo', (images == ''? 'No selected file' : images[0]?.file) )
      formData.append('redirect_link', addlink)
      formData.append('business_website', businessSite)
      formData.append('business_policy', businessPolicy)

      permissionList?.length >=2 ?
      AdminApis.createTieredLink(formData).then(
        (response) => {
          if (response?.data) {
            setLoader(false);
            toast.success(response?.data?.message);
            navigate('/multi-links')
          } else {
            toast.error(response?.response?.data?.message);
            setLoader(false);
          }
          
          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        // console.log(error.response);
        toast.error(error.response.data.message);
        setLoader(false);
      })
      :
      toast.error('You need to attach at least two links to create Multi-link');
    },
    [title, bio, name, addlink, businessPolicy, businessSite,permissionList]
  );

  const removeButton = React.useCallback(
    (idx) => {
      // const data = permissionList?.length == 0 ? permission : permissionList
      // const temp = [...permissionList];
      // temp.splice(idx, 1);
      // setPermissionList(temp)
      // console?.log(idx)

      const temp2 = [...permissionIdList];
      temp2.splice(idx, 1);
      setPermissionIdList(temp2)
    },
    [permissionIdList]
  );

  const permissionDropdownChange = React.useCallback(
    (value) => {
      let verify = 0;
      console?.log(value)
      if (value !== '') {
        permissionList?.map(
          (data, index) => (
            (data == value ? verify = 1 : '')
          ))

        if (verify == 0) {
          setPermissionList(permissionList => [...permissionList, value])
        }
        // 


      }
      



      // if (value !== '') {
      //   setPermissionList(permissionList => [...permissionList, value])

      //   permissionIdList?.map(
      //     (data, index) => (
      //       ((data?.name === value) ?
      //         removeButton(index)
      //         // setPermissionIdList(permissionIdList => [...permissionIdList, ''])
      //         :
      //         '')
      //     ),
      //   )
      // }
    },
    [permissionList, permissionIdList]
  );


  const remove = React.useCallback(
    (value, index) => {
      if (value !== '') {
        // setPermissionList(permissionList => [...permissionList, value])
        const temp = [...permissionList];
        temp.splice(index, 1);
        setPermissionList(temp)
        // setPermissionIdList(permissionIdList => [...permissionIdList, { 'name': value }])

        // permissionIdList?.map(
        //   (data, index) => (
        //     ((data?.name === value) ?
        //       removeButton(index)

        //       :
        //       '')
        //   ),
        // )
      }
    },
    [permissionList, permissionIdList]
  );

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded">
        <div className="rounded-t mb-0  pb-3 border-0">
          <div className=" lg:w-10/12 w-12/12">
            <div className="w-full px-4 max-w-full pt-2 flex-grow flex-1">
            <div className="bg-blue-100  rounded-lg ml-3 m-1 p-2 mb-3">
            <span className=" bg-blue-100  rounded-lg  text-gray-500 text-[12px]"><span className="mr-4 text-red-500 bg-red-200 p-1 px-3 rounded-full text-[15px]">!</span>  The Multi Link service lets you create a web page that combines all your Whatsapp links under one URL.</span>
              </div>
           
              {/* <p className="flex justify-center text-sm mb-4"></p> */}
              <div className="grid lg:gap-40 sm:gap-2 mb-6 md:grid-cols-2">

                {/* First Section */}
                {isAvailable ?
                  <form onSubmit={null}>
                    <div className="mb-4">
                      <span className="mt-20">
                        <p
                          className=" text-black font-bold"
                        >
                          Editor:
                        </p>
                      </span>
                      <div className="mt-3">
                        <label for="first_name" class="block mb-2 text-sm  text-gray-900 dark:text-gray-600">Search for your Multi link URL first</label>
                        <input type="text" defaultValue={name} onChange={(e) => setName(e?.target?.value)} id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="mygupta.co/username" required />
                      </div>
                      {(name.length <= 0) ? '' : (nameExist > 0 ? <span className="text-xs text-red-500">Name already exist</span> : <span className="text-xs text-green-500">Name Available</span>)}

                      <button
                        disabled={nameExist === 1 ? true : false}
                        style={{ backgroundColor: '#61A24F', borderRadius: '50px' }}
                        className="flex w-7/12 mb-2 px-10 mt-2 py-1.5 text-center text-white hover:bg-green-800  font-xs rounded-lg  "
                      >
                        Reserve Tiered Link
                      </button>

                      <label for="first_name" class="block mb-2 text-xs text-gray-600 ">The URL wont use links from your subscription plan</label>
                    </div>
                  </form>


                  :

                  <form onSubmit={handleSubmit}>
                    <div className={"mb-4"+ (loader ? 'shadow animate-pulse ' : '')}>
                      <span className="mt-0 flex justify-center">
                        <p className=" text-black font-bold">
                          <button
                            type="button"
                            style={{ backgroundColor: '#0071BC' }}
                            className="flex mb-2 px-10 mt-2 py-1.5 text-center text-white font-xs"
                          >
                            Editor:
                          </button>
                        </p>
                      </span>
                      <div className="mt-3">

                        <span className="cursor-pointer">
                          <ImageUploading
                            multiple
                            value={images}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                          >
                            {({
                              imageList,
                              onImageUpload,
                              onImageRemoveAll,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps,
                            }) => (
                              // write your building UI
                              <div className="upload__image-wrapper ">
                                <button
                                  type="button"
                                  disabled={imageList?.length ? true : false}
                                  style={isDragging ? { color: 'red' } : undefined}
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  {imageList?.length ? '' : <span className="flex justify-center mx-32 p-6 bg-gray-200 rounded-full"> <div> <img src={require('../assets/img/camera.png')} className="flex justify-center" /> <div className="flex justify-center text-xs">Upload</div> </div> </span>}

                                </button>
                                &nbsp;
                                {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                                {imageList?.map((image, index) => (
                                  <div key={index} className="image-item rounded-full">
                                    <div className="flex justify-center">
                                      <img src={image['data_url']} alt="" className=" mx-32 rounded-3xl max-h-20 max-w-sm" />
                                    </div>

                                    <div className="image-item__btn-wrapper flex justify-center">
                                      <button type="button" onClick={() => onImageUpdate(index)}>update</button> | &nbsp;
                                      <button type="button" onClick={() => onImageRemove(index)}>remove</button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </ImageUploading>
                        </span>
                        <label for="first_name" class="block mb-2 text-sm  text-gray-900 dark:text-gray-600">Brand name&nbsp; <b>(Keep it short)</b></label>
                        <input type="text" defaultValue={name} onChange={(e) => setName(e?.target?.value)} id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-1 block w-full p-2.5" placeholder="E.g mark-shoe-links" style={{ backgroundColor: '#F4FBFF' }} required />
                        
                        <div className="flex justify-between">
                        <div className="text-xs my-2 ml-1"> mygupta.co/{name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9\-]/g, '')}</div>
                        {(name.length <= 0) ? '': (nameExist > 0 ? <span className="text-xs text-red-500 my-2">Name already exist</span> : <span className="text-xs text-[#0071BC] my-2">Name Available</span>)}
                        </div>
                        
                        {/* {(name.length <= 0) ? <div className="text-xs my-2 ml-1"> mygupta.co/</div> : (nameExist > 0 ? <span className="text-xs text-red-500">Name already exist</span> : <span className="text-xs text-[#0071BC]">Name Available</span>)} */}

                        {/* <div className="flex justify-start gap-2">
                          <SvgElement type={icontypesEnum.QRCODE} />
                          <SvgElement type={icontypesEnum.COPY} />
                          <SvgElement type={icontypesEnum.UPARROW} />
                          <SvgElement type={icontypesEnum.DELETE} />
                        </div> */}

                        <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Title</label>
                        <input type="text" defaultValue={title} onChange={(e) => setTitle(e?.target?.value)} id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5" placeholder="Title of business here" style={{ backgroundColor: '#F4FBFF' }} />
                        <label for="first_name" class="block mb-2 mt-2 text-sm  text-gray-900 dark:text-gray-600">Bio</label>
                        <textarea id="message" defaultValue={bio} onChange={(e) => SetBio(e?.target?.value)} rows={3} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-gray-500 focus:border-gray-500" placeholder="Bio" style={{ backgroundColor: '#F4FBFF' }}></textarea>
                        <label for="first_name" class="mb-2 mt-2 text-sm  text-gray-900 dark:text-gray-600 flex justify-between"> <span>Select whatsapp link to add to your multilink</span>  <Link to={'/mylinks'} className=" cursor-pointer"><IoIosAddCircle color="#0071BC" size={20} /></Link></label>

                        <div>
                          <select onChange={(e) => { setAddLink(e?.target?.value); permissionDropdownChange(e?.target?.value) }} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <option value={''}>Select link</option>
                            {permissionIdList.map(
                              (data, index) => (
                                <option value={data?.name}>{data?.name}</option>
                              )
                            )}

                          </select>
                        </div>

                        <div className="mt-4">
                          {permissionList?.map(
                            (data, index) => (
                              <div key={index}>
                                <span
                                  style={{ backgroundColor: '#0071BC' }}
                                  className="flex mx-0 mb-2 justify-between px-2 py-1.5 text-center text-white hover:bg-green-800  font-medium rounded-lg  "
                                >
                                  <span>{data}</span>
                                  <span className=" rounded-lg px-1 cursor-pointer" onClick={() => remove(data, index)} style={{ backgroundColor: 'white', color: 'blue' }}>X</span>

                                </span>

                              </div>
                            ))}
                        </div>

                        <label for="first_name" class="block mb-2 mt-2 text-sm  text-gray-900 dark:text-gray-600">Busines website</label>
                        <input type="text"  onChange={(e) => setBusinessSite(e?.target?.value)} id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5" placeholder="https:// busines website" />


                        <label for="first_name" class="block mb-2 mt-2 text-sm  text-gray-900 dark:text-gray-600">Business policy</label>
                        <input type="text"  onChange={(e) => setBusinessPolicy(e?.target?.value)} id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5" placeholder="Business policy" />

                      </div>
                      {/* {nameExist > 0 ? <span className="text-xs text-red-500">Name already exist</span> : <span className="text-xs text-green-500">Name Available</span>} */}

                      <div className="flex justify-center">
                        <button
                          disabled={(nameExist === 1 || data?.length <= 1) ? true : false}
                          type="submit"
                          style={{ backgroundColor: ((nameExist === 1 || data?.length <= 1) ? 'grey' : "#0071BC") }}
                          className="px-4 cursor-pointer mb-2 mt-2 py-1.5 text-center text-white font-xs rounded-lg"
                        >
                          Publish Multilink
                        </button>
                      </div>

                    </div>
                  </form>
                }


                {/* Second Section */}



                <div className="mb-4">
                  <p className=" text-black mb-5 font-bold flex justify-center">
                    <button
                      type="button"
                      style={{ backgroundColor: '#0071BC' }}
                      className="flex mb-2 px-10 mt-2 py-1.5 text-center text-white font-xs"
                    >
                      Preview:
                    </button>
                  </p>
                  <div class="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md ">
                    <div class="mb-20 mt-4">
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper">
                            <button
                              disabled={imageList?.length ? true : false}
                              style={isDragging ? { color: 'red' } : undefined}
                              onClick={null}
                              {...dragProps}
                            >
                              {imageList?.length ? '' : <span className="flex justify-center mx-32 p-6 bg-gray-200 rounded-full"> <div> <img src={require('../assets/img/camera.png')} className="flex justify-center" /> <div className="flex justify-center text-xs">Upload</div> </div> </span>}

                            </button>
                            &nbsp;
                            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                            {imageList.map((image, index) => (
                              <div key={index} className=" flex justify-center rounded-full">
                                <img src={image['data_url']} alt="" className="mx-32 flex rounded-full max-h-20 max-w-fit" />
                                <div className="image-item__btn-wrapper flex justify-center">
                                  {/* <button onClick={() => onImageUpdate(index)}>Update</button> | &nbsp;
                                      <button onClick={() => onImageRemove(index)}>Remove</button> */}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </ImageUploading>

                      {/* <span className="flex mt-10 justify-center mx-32 p-6 bg-gray-200 rounded-full"> <div> <img src={require('../assets/img/camera.png')} className="flex justify-center" /> <div className="flex justify-center text-xs">Upload</div> </div> </span> */}

                      <h5 class=" flex justify-center mb-2 text-md pt-5 font-bold tracking-tight text-gray-900 ">{title == '' ? "Title" : title} </h5>

                      <div className="flex justify-center mb-2 mx-2">
                        <p >{bio == '' ? "Bio" : bio}</p>
                      </div>


                      {permissionList?.map(
                        (data, index) => (
                          <NavLink to={`localhost:8000/${data}`} key={index}>
                            <span
                              style={{ backgroundColor: '#0071BC' }}
                              className="flex mx-5 mb-2 justify-center px-10 py-1.5 cursor-pointer text-white hover:bg-blue-800  font-medium rounded-lg  "
                            >
                              <span className="flex justify-between">
                                <span><SvgElement type={icontypesEnum.WHATSAPP} /> </span>
                                <span>{data}</span>
                              </span>

                            </span>

                          </NavLink>
                        ))}





                    </div>
                    <hr className="mb-[46px]" />

                    <div className="flex justify-center mb-10">
                      <div className="flex flex-col">
                        <div className="ml-3 mb-5">More Info:</div>
                        <div
                          className="flex mb-2 justify-center border border-[#0071BC] px-2 w-30 py-1.5 cursor-pointer text-[#0071BC] hover:border-blue-800  rounded-lg  "
                        >
                          <span className="flex justify-between">
                            <span className="pl-3">Visit</span>
                            <span className="px-3  pt-1"><SvgElement type={icontypesEnum.EXPLORE} /> </span>

                          </span>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="flex justify-center mt-3" >
                    <span style={{ fontSize: '16px', fontWeight: '300' }}>Powered By Gupta</span>
                  </div>

                </div>



              </div>

            </div>

          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}

        </div>
      </div>


      {
        (data?.length <= 1) ?

          <section>
            <Modal
              visible={true}
              width="400"
              height="210"
              effect="fadeInUp"
              onClickAway={() => null}
            >
              <div className=" " style={{ overflow: 'auto' }}>
                <span className="flex justify-end px-3 pt-3">
                  <NavLink to={'/mylinks'}>
                  <p className="cursor-pointer font-bold" onClick={(e) => null}><SvgElement type={icontypesEnum.CANCEL} /></p>
                  </NavLink>
                  
                </span>


                <div className="flex justify-center">
                  <label
                    className="flex justify-start  mb-2 pt-1 text-md font-bold text-black"
                  >
                    NOTIFICATION !!!
                  </label>
                </div>

                <div className="flex justify-center">
                  <label
                    className=" px-10 mb-2 pt-2 text-xs text-[13px] text-gray-600"
                  >
                    To create a multi link, you need to have 2 or more links created.
                  </label>
                </div>

                <div className="flex justify-center my-3">
                  <NavLink to={'/mylinks'}>
                <button
                  type="button"
                  style={{ backgroundColor: '#0071BC' }}
                  className=" mb-2 px-10 mt-2 py-1.5 text-center text-white font-xs rounded"
                >
                  Create Link
                </button>
                </NavLink>
                </div>

                



              </div>
            </Modal>
          </section>
          :
          ""
      }

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}
